import '../../components/studies/Econsult/Econsult.less'

import React from 'react'

import { BasicLayout } from '../../components/BasicLayout'
import { EconsultMeetingContextProvider } from '../../components/studies/Econsult/EconsultMeetingContext'
import { EconsultSubjectMeeting } from '../../components/studies/Econsult/EconsultSubjectMeeting'

const IndexPage: React.FC = () => {
  return (
    <div className="econsult-meeting__view">
      <BasicLayout hideMenu>
        <EconsultMeetingContextProvider>
          <EconsultSubjectMeeting />
        </EconsultMeetingContextProvider>
      </BasicLayout>
    </div>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - eConsult Meeting</title>
