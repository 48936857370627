import './EconsultMeetingEnded.less'

import React from 'react'

import MeetingEndedIllustration from '../../../../assets/images/meeting-ended-illustration.svg'
import { useScopedIntl } from '../../../../hooks'
import { DatacTitle } from '../../../common'

export const EconsultMeetingEnded: React.FC = () => {
  const eConsultIntl = useScopedIntl('studies.econsult')

  return (
    <div className="econsult-meeting-ended">
      <MeetingEndedIllustration />
      <DatacTitle type="h1">{eConsultIntl('meeting_ended')}</DatacTitle>
    </div>
  )
}
